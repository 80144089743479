import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <>
    {children}
  </>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Privacy policy`}</h1>
    <p>{`We respect your privacy and this policy explains how we collect and treat any information you give us. You won’t find any complicated legal terms or long passages of unreadable text. We’ve no desire to trick you into agreeing to something you might later regret.`}</p>
    <h3>{`Our policy covers`}</h3>
    <ul>
      <li parentName="ul">{`Why we value your privacy`}</li>
      <li parentName="ul">{`How we collect information`}</li>
      <li parentName="ul">{`What information we hold`}</li>
      <li parentName="ul">{`Where we store your information`}</li>
      <li parentName="ul">{`What we use your information for`}</li>
      <li parentName="ul">{`Who’s responsible for your information at our company`}</li>
      <li parentName="ul">{`Who has access to information about you`}</li>
      <li parentName="ul">{`The steps we take to keep your information private`}</li>
      <li parentName="ul">{`How to complain`}</li>
      <li parentName="ul">{`Changes to the policy`}</li>
      <li parentName="ul">{`Why we value your privacy`}</li>
    </ul>
    <p>{`We value your privacy as much as we do our own, so we’re committed to keeping your personal and business information safe.`}</p>
    <p>{`We’re uncomfortable with the information companies, governments, and other organisations keep on file, so we ask for only the bare minimum from our customers.`}</p>
    <p>{`We’ll never use your personal information for any reason other than why you gave it, and we’ll never give anyone access to it unless we’re forced to by law.`}</p>
    <h3>{`How we collect information`}</h3>
    <p>{`We collect your email address when you sign up for one of our newsletters.`}</p>
    <p>{`We ask for your account and contact information when you hire or buy something from us.`}</p>
    <h3>{`What information we hold`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`When you contact us by email or through our website, we collect your name, email address, and the company you work for, if you’ve given us that.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If you sign up for a newsletter, we only collect your email address.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If you do business with us, we also collect your business name and bank details and keep records of the invoices we send you and the payments you make.`}</p>
      </li>
    </ul>
    <h3>{`Where we store your information`}</h3>
    <p>{`If you sign up for a newsletter, we store your email address in Mailchimp, which is the marketing platform we prefer. We chose this system partly for their commitment to security.`}</p>
    <h3>{`What we use your information for`}</h3>
    <p>{`We occasionally use your contact information to send you details of our products and services. When we do, you have the option to unsubscribe from these communications and we won’t send them to you again.`}</p>
    <p>{`We might also email you about our products and services, but if you tell us not to, we won’t get in touch again. We will use your information to send you invoices, statements, or reminders.`}</p>
    <h3>{`Who’s responsible for your information at our company`}</h3>
    <p><strong parentName="p">{`roy@csspurge.com`}</strong>{` is responsible for the security of your information. You can contact him by email if you have any concerns about the information we store.`}</p>
    <h3>{`Who has access to information about you`}</h3>
    <p>{`When we store information in our own systems, only the people who need it have access.`}</p>
    <h3>{`The steps we take to keep your information private`}</h3>
    <p>{`Where we store your information in third-party services, we restrict access only to people who need it. We store passwords in 1Password, an encrypted password manager, use a different, randomly generated password for each service, and never use the same password twice.`}</p>
    <p>{`The computers we use are all protected by a passcode or fingerprint access. These computers ask for authentication whenever they’re started or after 5 minutes of inactivity. Our mobile devices are also protected by a passcode, fingerprint or facial recognition.`}</p>
    <h3>{`How to complain`}</h3>
    <p>{`We take complaints very seriously. If you’ve any reason to complain about the ways we handle your privacy, please contact us by email at `}<strong parentName="p">{`roy@csspurge.com`}</strong></p>
    <h3>{`Changes to the policy`}</h3>
    <p>{`If we change the contents of this policy, those changes will become effective the moment we publish them on our website.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      